import Affiliate from '~/client-only-imports/affiliate.client';
import { useEffect } from 'react';
import type { BrandedPage } from '@cardo/types';
import { useLocation } from '@remix-run/react';

export function useSubAffiliate(foundPage: BrandedPage) {
  const location = useLocation();

  useEffect(() => {
    const aff = Affiliate.create({
      log: process.env.NODE_ENV === 'development',
      tags: [
        {
          hosts: [
            'localhost',
            'localhost:3000',
            'cardonomics.com',
            'www.cardonomics.com',
          ],
          modify: (url) => {
            if (url.pathname.includes('/go')) {
              url.searchParams.set(
                'influencer',
                foundPage.attributes.influencer.data?.attributes.username
              );
              url.searchParams.set('fromPath', location.pathname);
            }
            return url;
          },
        },
      ],
    });

    aff.attach();

    return () => {
      Affiliate.detachAll();
      Affiliate.revert();
    };
  }, [foundPage, location.pathname]);
}
